<template>
    <div class="com-lines-record">
        <zj-search-bar :search-form="searchForm" show-search @search="onSearch"></zj-search-bar>
        <zj-table :height="500" :table-header="tableHeader" :table-data="tableData"></zj-table>
    </div>
</template>

<script>
import ajax from '@/ajax.js'
import XDate from '@/utils/x-date.js'
import {ZjForm, ZjTable, ZjSearchBar} from 'zj-element';
export default {
    name: 'ComLines',
    components: {
        ZjForm,
        ZjTable,
        ZjSearchBar,
    },
    props: {
    },
    data(){
        return {
            currentId: null,
            tableHeader: [
                { type: 'text', key: 'id', value: 'ID' },
                { type: 'text', key: 'ping', value: 'Ping' },
                { type: 'text', key: 'channel', value: '通道' },
                { type: 'text', key: 'temperature', value: '温度' },
                // { type: 'text', key: 'timestamp', value: '时间' },
                { type: 'text', key: 'time', value: '时间', sortable: true, },
            ],
            tableData: [],
            searchForm: [
                { key: 'ID', value: '', tip: '设备ID', type: 'select', 
                    options: [
                        { value: 0, },
                    ],
                },
                { key: 'channel', value: '', tip: '通道', type: 'select', 
                    options: [
                        { value: 0, label: '热端', },
                        { value: 1, label: '冷端', },
                    ],
                },
                { key: 'start-time', value: '', tip: '开始时间', type: 'date', },
                { key: 'end-time', value: '', tip: '结束时间', type: 'date', },
            ],
        }
    },
    computed:{
        
    },
    created(){
    },
    mounted(){
        this.downloadId();
    },
    methods:{
        downloadId(){
            ajax({
                // url: 'https://mttu.muztak.cn/api/overview',
                // url: 'http://eme_system.hzhuishi.cn:8090/api/soldier',
                url: '/api/overview/',
            })
            .then( res =>{
                // console.log("download:", res );
                res.map(it => {
                    this.currentId = it.id;
                    this.download()
                });
                // this.sentries = res;
            });
            
        },
        download(){
            if(this.currentId === null)
                return;
            ajax({
                url: '/api/cold/'+this.currentId,
                method: "get",
                param: {
                    // date: XDate.formatTimestamp('Y-m')
                },
            }).then(list => {
                console.log("download:", list );
                list['0'].map(it => {
                    it.time = it.timestamp ? XDate.formatTimestamp('Y-m-d H:i:s', XDate.toTimeStamp(it.timestamp.replace('T', ' '))) : '';
                    it.temperature = it.temperature.toFixed(3);
                })
                this.tableData = list['0'];
                // console.log("download end:", this.xAxis, this.datas)
            });
        },
        onSearch(){
            this.downloadId();
        },
    },
    watch:{
       
    },
}
</script>


<style>
.com-lines-record{width: auto; /* height: 500px; */}
</style>

